<template>

  <div class="pros" v-if="list">
 
    <div class="params">
      <template v-for="st in setups">
        <div class="item">
          <i v-if="st.icon" :class="'icn_par ' + st.icon"></i>        
          <span class="nm">{{ st.title }}</span>
          <span class="vl">{{ st.value }}</span>
        </div>
      </template>
    </div>
  </div>

</template>

<script setup lang="ts">

import { 
    ProductTentTypeTrShort, 
    ProductSeedTypeTrShort, 
    ProductSeedGenTypeNames, 
    ProductLampTypeTrShort,
    ProductNutrientStyleTrShort,
    ProductNutrientTypeTrShort,
    ProductNutrientStateTrShort,
} from '@/types/product'

const { t } = useI18n();
const { $plurals } = useNuxtApp();

const props = defineProps({
  list: {
    type: Object,
    default: ''
  },
  type: {
    type: String,
    default: ''
  },
})

const type = ref(props.type);
const data = ref(props.list);

const maxMin = (min, max) => {
  if(max && min){
    return min + ' - ' + max;
  }else if(max){
    return max;
  }else if(min){
    return min;
  }else{
    return '';
  }
}

const setups = computed(() => {
  var arr = [];
  var vl ='';
  var k = '';


  var keys = Object.keys(data.value);


  // 

  // seed setups
  if(type.value == 'seed'){

      if(keys.indexOf('type_of') >= 0 && data.value['type_of']){
        vl = data.value['type_of'];
        var ls = vl.split(',');

        var childs = ls.map((i)=>{
          return ProductSeedTypeTrShort[i] ? t(ProductSeedTypeTrShort[i]) : '';
        });

        var item = {
          title: t('strain_view_features_gender'),
          value: childs.join(' & '),
          icon: 'icon-prop-' + ls[0],
        }
        arr.push(item);
      }

      // 
      if(keys.indexOf('indica_sativa') >= 0 && data.value['indica_sativa'] !== null){
        vl = data.value['indica_sativa'];
        var item = {
          title: t('strain_view_features_genes'),
          value: vl + '% ' + (ProductSeedGenTypeNames[1] ? t(ProductSeedGenTypeNames[1]) : '') + ' / ' + (100 - vl) + '% ' + (ProductSeedGenTypeNames[2] ? t(ProductSeedGenTypeNames[2]) : ''),
          icon: 'icon-prop-genes',
        }
        arr.push(item);
      }

      // 
      if(keys.indexOf('gen') >= 0 && data.value['gen']){
        var item = {
          title: t('strain_view_features_genetics'),
          value: data.value['gen'],
          icon: 'icon-prop-genetic',
        }
        arr.push(item);
      }

      // 

      if(
        (keys.indexOf('harvest_min') >= 0 && data.value['harvest_min']) 
        || 
        (keys.indexOf('harvest_max') >= 0 && data.value['harvest_max'])){
        var item = {
          title: t('strain_view_features_harvest'),
          value: maxMin(data.value['harvest_min'], data.value['harvest_max']) + ' ' + t('set_gram') + '/' + t('set_sqm'),
          icon: 'icon-prop-harvest',
        }
        arr.push(item);
      }

      // 
      if(
        (keys.indexOf('flowering_min') >= 0 && data.value['flowering_min']) 
        || 
        (keys.indexOf('flowering_max') >= 0 && data.value['flowering_max'])){
        var item = {
          title: t('strain_view_features_flowering'),
          value: maxMin(data.value['flowering_min'], data.value['flowering_max']) + ' ' + $plurals.get('day', 2),
          icon: 'icon-prop-flowering',
        }
        arr.push(item);
      }

      // 
      var thcMax = data.value['thc_max'] ? data.value['thc_max'] : (data.value['thc'] > 0 ? data.value['thc'] : null);
      if(
        (keys.indexOf('thc_min') >= 0 && data.value['thc_min']) 
        || 
        (keys.indexOf('thc_max') >= 0 && thcMax)){
        var item = {
          title: t('strain_view_features_thc'),
          value: maxMin(data.value['thc_min'], thcMax) + '%',
          icon: 'icon-prop-flowering',
        }
        arr.push(item);
      }


      var cbdMax = data.value['cbd_max'] ? data.value['cbd_max'] : (data.value['cbd'] > 0 ? data.value['cbd'] : null);
      if(
        (keys.indexOf('cbd_min') >= 0 && data.value['cbd_min']) 
        || 
        (keys.indexOf('cbd_max') >= 0 && cbdMax)){
        var item = {
          title: t('strain_view_features_cbd'),
          value: maxMin(data.value['cbd_min'], cbdMax) + '%',
          icon: 'icon-prop-flowering',
        }
        arr.push(item);
      }
        
        

      if(
        (keys.indexOf('cbg_min') >= 0 && data.value['cbg_min']) 
        || 
        (keys.indexOf('cbg_max') >= 0 && data.value['cbg_max'])){
        var item = {
          title: t('strain_view_features_cbg'),
          value: maxMin(data.value['cbg_min'], data.value['cbg_max']) + '%',
          icon: 'icon-prop-flowering',
        }
        arr.push(item);
      }
        

      // 
      if(
        (keys.indexOf('vegetation_min') >= 0 && data.value['vegetation_min']) 
        || 
        (keys.indexOf('vegetation_max') >= 0 && data.value['vegetation_max'])){
        var item = {
          title: t('strain_view_features_vegetation'),
          value: maxMin(data.value['vegetation_min'], data.value['vegetation_max']) + ' ' + $plurals.get('day', 2),
          icon: 'icon-prop-flowering',
        }
        arr.push(item);
      }
      // 
      if(keys.indexOf('ruderalis') >= 0 && data.value['ruderalis']){
        var item = {
          title: t('strain_view_features_ruderalis'),
          value: data.value['ruderalis'] + '%',
          icon: 'icon-prop-flowering',
        }
        arr.push(item);
      }
      
      // 
      if(keys.indexOf('indoor') >= 0 && data.value['indoor']){
        var item = {
          title: t('strain_view_features_type_title'),
          value: t('universal_type_room_indoor'),
          icon: 'icon-prop-indoor',
        }
        arr.push(item);
      }

      if(keys.indexOf('outdoor') >= 0 && data.value['outdoor']){
        var item = {
          title: t('strain_view_features_type_title'),
          value: t('universal_type_room_outdoor'),
          icon: 'icon-prop-outdoor',
        }
        arr.push(item);
      }

      if(keys.indexOf('greenhouse') >= 0 && data.value['greenhouse']){
        var item = {
          title: t('strain_view_features_type_title'),
          value: t('universal_type_room_greenhouse'),
          icon: 'icon-prop-greenhouse',
        }
        arr.push(item);
      }

  }

  // lamp setups
  if(type.value == 'lamp'){

      if(keys.indexOf('type') >= 0 && data.value['type']){
        var item = {
          title: t('lights_view_seed_type'),
          value: ProductLampTypeTrShort[data.value['type']] ? t(ProductLampTypeTrShort[data.value['type']]) : '',
          icon: 'icon-prop-type',
        }
        arr.push(item);
      }

      if(keys.indexOf('subtype') >= 0 && data.value['subtype']){
        var item = {
          title: t('lights_view_seed_subtype'),
          value: ProductLampTypeTrShort[data.value['subtype']] ? t(ProductLampTypeTrShort[data.value['subtype']]) : '',
          icon: 'icon-prop-subtype',
        }
        arr.push(item);
      }

      if(keys.indexOf('power') >= 0 && data.value['power']){
        var item = {
          title: t('lights_view_seed_power'),
          value: data.value['power'],
          icon: 'icon-prop-power',
        }
        arr.push(item);
      }

      if(keys.indexOf('size') >= 0 && data.value['size']){
        var item = {
          title: t('lights_view_seed_size'),
          value: data.value['size'],
          icon: 'icon-prop-size',
        }
        arr.push(item);
      }

      if(keys.indexOf('coverage') >= 0 && data.value['coverage']){
        var item = {
          title: t('lights_view_seed_coverage'),
          value: data.value['coverage'],
          icon: 'icon-prop-coverage',
        }
        arr.push(item);
      }

      if(keys.indexOf('weigth') >= 0 && data.value['weigth']){
        var item = {
          title: t('lights_view_seed_weigth'),
          value: data.value['weigth'] + 'Kg',
          icon: 'icon-prop-weigth',
        }
        arr.push(item);
      }

  }

  // tent setups
  if(type.value == 'tent'){

      if(keys.indexOf('type') >= 0 && data.value['type']){
        var item = {
          title: t('tents_view_tent_type'),
          value: ProductTentTypeTrShort[data.value['type']] ? t(ProductTentTypeTrShort[data.value['type']]) : '',
          icon: 'icon-prop-type',
        }
        arr.push(item);
      }

      if(keys.indexOf('power') >= 0 && data.value['power']){
        var item = {
          title: t('tents_view_tent_power'),
          value: data.value['power'],
          icon: 'icon-prop-power',
        }
        arr.push(item);
      }

      if(keys.indexOf('width') >= 0 && data.value['width']){
        var item = {
          title: t('tents_view_tent_width'),
          value: data.value['width'],
          icon: 'icon-prop-width',
        }
        arr.push(item);
      }

      if(keys.indexOf('length') >= 0 && data.value['length']){
        var item = {
          title: t('tents_view_tent_length'),
          value: data.value['length'],
          icon: 'icon-prop-length',
        }
        arr.push(item);
      }

      if(keys.indexOf('height') >= 0 && data.value['height']){
        var item = {
          title: t('tents_view_tent_height'),
          value: data.value['height'],
          icon: 'icon-prop-height',
        }
        arr.push(item);
      }

      if(keys.indexOf('square') >= 0 && data.value['square']){
        var item = {
          title: t('tents_view_tent_square'),
          value: data.value['square'],
          icon: 'icon-prop-square',
        }
        arr.push(item);
      }

  }

  // nutrient setups
  if(type.value == 'nutrient'){

      if(keys.indexOf('type') >= 0 && data.value['type']){
        var item = {
          title: t('product_view_features_nutrient_type'),
          value: ProductNutrientTypeTrShort[data.value['type']] ? t(ProductNutrientTypeTrShort[data.value['type']]) : '',
          icon: 'icon-prop-type',
        }
        arr.push(item);
      }

      if(keys.indexOf('style') >= 0 && data.value['style']){
        var item = {
          title: t('product_view_features_feeding_style'),
          value: ProductNutrientStyleTrShort[data.value['style']] ? t(ProductNutrientStyleTrShort[data.value['style']]) : '',
          icon: 'icon-prop-style',
        }
        arr.push(item);
      }

      if(keys.indexOf('ph') >= 0 && data.value['ph']){
        var item = {
          title: 'Ph',
          value: data.value['ph'],
          icon: 'icon-prop-ph',
        }
        arr.push(item);
      }

      if(keys.indexOf('organic') >= 0 && data.value['organic']){
        var item = {
          title: t('product_view_features_organic_nutrient'),
          value: data.value['organic'] == 1 ? t('product_view_organic_nutrient_yes') : t('product_view_organic_nutrient_no'),
          icon: 'icon-prop-organic',
        }
        arr.push(item);
      }

      if(keys.indexOf('state') >= 0 && data.value['state']){
        var item = {
          title: t('product_view_features_content_state'),
          value: ProductNutrientStateTrShort[data.value['state']] ? t(ProductNutrientStateTrShort[data.value['state']]) : '',
          icon: 'icon-prop-state',
        }
        arr.push(item);
      }

  }

  //---------------------------------
 

  if(keys.indexOf('release_year') >= 0 && data.value['release_year']){
    var item = {
      title: t('strain_view_release_year'),
      value: data.value['release_year'] + (data.value['discontinued'] ? ', ' + t('strain_view_discontinued') : ''),
      icon: null,
    }
    arr.push(item);
  }



      
  return arr;
})


</script>

<style scoped>
 
 
.params{
  text-align: left;
  display: flex;
  vertical-align: middle;
  flex-wrap: wrap;
  gap: 0.6rem;
  max-width: 700px;
}

.params .item{
  width: calc(100% / 2 - 10px);
  /* padding-bottom: 15px; */
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.params .vl{
  display: inline-block;
  margin-left: 10px;
}

.params .nm{
  font-weight: bold;
}
.icn_par{
  display: flex;
  color: white;
  background-color: var(--un-firm);
  border-radius: 50px;
  padding: 6px;
  font-size: 1rem; 
}

@container pb (max-width: 700px) {
  .params .item{
    width: 100%;
  }
}

</style>
